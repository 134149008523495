export const DEFAULT_USER = {
    email: '',
    firstName: '',
    last_name: '',
    phoneNumber: '',
    companyName: '',
    timeZone: '',
    hourFormat: 24,
    subscribed: true,
    password: '',
    enabled: true,
    role: '',
};

export const USER_ROLES = [
    { text: 'User', value: 'ROLE_USER' },
    { text: 'License Admin', value: 'ROLE_LICENSE_ADMIN' },
];
