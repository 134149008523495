











































































































































import Vue from 'vue';
import moment from 'moment-timezone';
import UserApi from '@/services/api/UserApi';
import CommonRules from '@/rules/CommonRules';
import { Component } from 'vue-property-decorator';
import { DEFAULT_USER, USER_ROLES } from '@/models/UserModel';

@Component
export default class CreateUserDialog extends Vue  {
    public user: any = Object.assign({}, DEFAULT_USER);
    public dialog: boolean = false;
    public rules = CommonRules;
    public pwShow: boolean = false;
    public timeZones: string[] = [];
    public confirmPasswordShow: boolean = false;
    public confirmPassword: string = '';
    public roles: any[] = USER_ROLES;

    public created(): void {
        this.getTimeZones();
    }

    public close(): void {
        this.dialog = false;
        this.user = Object.assign({}, DEFAULT_USER);
        (this.$refs.form as any).resetValidation();
    }

    public save(): void {
        if ((this.$refs.form as any).validate()) {
            UserApi.createUser(this.user).then(() => {
                this.$store.commit(
                    'notificationState/setSuccessMessage',
                    `User ${this.user.email} has been successfully created`,
                );

                this.$emit('added');

                this.confirmPassword = '';

                this.close();
            }).catch(error => {
                console.error(error);
                this.$store.commit('notificationState/setErrorMessage', error.response.data.error);
            });
        }
    }

    public getTimeZones(): void {
        const timeZones: string[] = moment.tz.names();
        const offsetTmz: string[] = [];

        timeZones.forEach((timeZone: string) => {
            const offset: string = `(GMT${moment.tz(timeZone).format('Z')}) ${timeZone}`;

            offsetTmz.push(offset);
        });

        this.timeZones = offsetTmz;
    }
}
