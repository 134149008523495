import DateUtil from '@/utils/DateUtil';

export default class UserTransformer {
    public static getTransformed(users: any[]): any {
        const dateFormat: string = 'ddd MMM DD HH:mm:ss YYYY ZZ';

        return users.map(user => {
            const tz: string = user.timeZone ? user.timeZone : '';

            user.lastLogin = user.lastLogin
                ? DateUtil.millisecondsToDateFormat(user.lastLogin * 1000, dateFormat, tz)
                : null;

            return user;
        });
    }
}
