




















































































































import UserApi from '@/services/api/UserApi';
import { Component, Watch } from 'vue-property-decorator';
import UserTransformer from '@/transformers/UserTransformer';
import UsersService from '@/services/users/UsersSortService';
import { ProfileInterface } from '@/interfaces/ProfileStateInterface';
import UserManagement from '@/components/UsersManagement/UserManagement';
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue';
import CreateUserDialog from '@/components/UsersManagement/CreateUserDialog.vue';
import UserDetailsDialog from '@/components/UsersManagement/UserDetailsDialog.vue';
import EditPasswordDialog from '@/components/UsersManagement/EditPasswordDialog.vue';

@Component({
    components: {
        CreateUserDialog,
        UserDetailsDialog,
        ConfirmationDialog,
        EditPasswordDialog,
    },
})
export default class UsersList extends UserManagement {
    public paginate: any = {};
    public totalItems: number = 0;
    public statuses: any[] = [
        { text: 'Enabled', value: true },
        { text: 'Disabled', value: false },
    ];
    public status: null|boolean = null;
    public roles: any[] = [
        { text: 'Admin', value: 'ROLE_ADMIN' },
        { text: 'License Admin', value: 'ROLE_LICENSE_ADMIN' },
        { text: 'All', value: 'All' },
    ];
    public role: string = 'All';
    public unwatchAdminRedirect: any = null;
    public shouldDropPage: boolean = false;

    @Watch('paginate')
    public optionsChanged(): void {
        if (! this.shouldDropPage) {
            this.getUsers();
        }
    }

    @Watch('status')
    public statusChanged(): void {
        this.shouldDropPage = true;

        this.getUsers();
    }

    @Watch('role')
    public roleChanged(): void {
        this.shouldDropPage = true;

        this.getUsers();
    }

    public doSearch(): void {
        this.shouldDropPage = true;

        this.getUsers();
    }

    public created(): void {
        this.unwatchAdminRedirect = this.$store.watch(state => state.profile.profile, () => {
            if (! this.isAdmin || ! this.isLicenseAdmin) {
                this.$router.push({ name: 'licensing-report' });
            }
        });
    }

    public beforeDestroy(): void {
        this.unwatchAdminRedirect();
    }

    public getHumanReadableRoles(roles: string[]): string {
        return UsersService.getHumanReadableRoles(roles);
    }

    public changeStatus(user: any): void {
        UserApi.changeUserStatus(user.enabled, user.id).then(() => {
            const userStatus: string = user.enabled ? 'enabled' : 'disabled';

            this.$store.commit(
                'notificationState/setSuccessMessage',
                `User ${user.email} has been successfully ${userStatus}`,
            );
        }).catch(error => {
            console.error(error);

            this.$store.commit('notificationState/setErrorMessage', error.response.data.error);
        });
    }

    public isHimself(id: number): boolean {
        return id === this.profile.id;
    }

    public get profile(): ProfileInterface {
        return this.$store.getters['profile/getProfile'];
    }

    public get isAdmin(): boolean {
        return this.$store.getters['profile/isAdmin'];
    }

    public get isLicenseAdmin(): boolean {
        return this.$store.getters['profile/isLicenseAdmin'];
    }

    public getLastLogin(item: any): string {
        if (! item.lastLogin) {
            return 'Never';
        }

        return item.lastLogin;
    }

    public getUsers(): void {
        this.$store.commit('notificationState/setSpinnerMessage', 'Getting users...');

        this.search = this.search ? this.search.replace(/\s+/g, '') : '';

        if (this.shouldDropPage) {
            this.paginate.page = 1;
        }

        UserApi.getUsers(this.paginate, this.search, this.status, this.role).then(response => {
            this.items = UserTransformer.getTransformed(response.data.users);
            this.totalItems = response.data.totalItems;

            this.shouldDropPage = false;
        }).catch(error => {
            console.error(error);

            this.items = [];
            this.totalItems = 0;
        }).finally(() => {
            this.$store.commit('notificationState/clearSpinnerMessage');
        });
    }

    public remove(user: any): void {
        const msg: string = `Are you sure you want to remove user ${user.email}?`;

        this.confirmUserRemoving(msg).then((confirmed: boolean) => {
            if (confirmed) {
                UserApi.deleteUser(user.id).then(() => {
                    this.$store.commit(
                        'notificationState/setSuccessMessage',
                        `User ${user.email} has been successfully removed`,
                    );

                    this.removeFromList(user);
                }).catch(error => {
                    console.error(error);

                    this.$store.commit('notificationState/setErrorMessage', error.response.data.error);
                });
            }
        });
    }

    public resetSearch(): void {
        this.search = '';

        this.getUsers();
    }
}
