















































































import Vue from 'vue';
import UserApi from '@/services/api/UserApi';
import CommonRules from '@/rules/CommonRules';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class EditPasswordDialog extends Vue  {
    @Prop({ required: true, type: Object })
    public user!: any;
    public dialog: boolean = false;
    public password: string = '';
    public valid: boolean = true;
    public rules = CommonRules;
    public pwShow: boolean = false;
    public confirmPasswordShow: boolean = false;
    public confirmPassword: string = '';
    public licenseAdmin: boolean = false;
    public showPasswords: boolean = false;
    public disabledForm: boolean = true;

    public created(): void {
        this.licenseAdmin = this.user.roles.includes('ROLE_LICENSE_ADMIN');
    }

    public close(): void {
        this.password = '';
        this.confirmPassword = '';
        this.dialog = false;
    }

    @Watch('showPasswords')
    public onPasswordsChange(): void {
        this.disabledForm = false;
    }

    public enableUpdate(): void {
        this.disabledForm = false;
    }

    public isEditDisabled(): boolean {
        return this.user.id === this.$store.getters['profile/getProfile'].id;
    }

    public update(): void {
        if ((this.$refs.form as any).validate()) {
            const data: any = {};

            if (this.licenseAdmin) {
                data.role = 'ROLE_LICENSE_ADMIN';
            }

            if (this.password) {
                data.password = this.password;
            }

            UserApi.updateUser(this.user.id, data).then(response => {
                if (response.data.success) {
                    this.close();

                    this.$store.commit(
                        'notificationState/setSuccessMessage',
                        `User ${this.user.email} password has been successfully updated`,
                    );

                    this.$emit('updated');

                    this.password = '';
                    this.confirmPassword = '';
                } else {
                    this.$store.commit('notificationState/setErrorMessage', response.data.error);
                }
            }).catch(error => {
                console.error(error);
                this.$store.commit('notificationState/setErrorMessage', error.response.data.error);
            });
        }
    }

    public showRolesSelect(): boolean {
        return this.user.id !== this.$store.getters['profile/getProfile'].id;
    }
}
