






































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import UsersService from '@/services/users/UsersSortService';

@Component
export default class UserDetailsDialog extends Vue {
    @Prop({ required: true, type: Object })
    public user!: any;
    public dialog: boolean = false;

    public close(): void {
        this.dialog = false;
    }

    public getDetail(detail: any): string {
        return detail ? detail : '-';
    }

    public getHumanReadableRoles(roles: string[]): string {
        return UsersService.getHumanReadableRoles(roles);
    }
}
